// Importing react and style
import './style.scss';
import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Importing Custom Pages
import Register from './pages/Register';
import Login from './pages/Login';
import Bales from './pages/Bales';
import Bale from './pages/Bale';
import LoadingScreen from './pages/LoadingScreen';

// Importing context provider. 
import { AuthContext } from './context/AuthContext';
import { MaterialsContextProvider } from './context/MaterialsContext';

// Accessing firebase resources initialized in the "firebase.js"
import { auth, firestore, storage } from './context/firebase';

function App() {
  // Creating the user 
  const {currentUser} = useContext(AuthContext);

  // Creating a protected route to only allow logged-in users to access the dashboard. 
  const ProtectedRoute = ({children}) => {
    if(!currentUser){
      return <Navigate to="/login"/>
    }
    // else
    return children
  }

  return (
    <MaterialsContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<ProtectedRoute><Bales auth={auth} storage={storage} firestore={firestore}/></ProtectedRoute>} />
            <Route path="login" element={<Login/>} />
            <Route path="register" element={<Register auth={auth} storage={storage} firestore={firestore}/>} />
            <Route path="bale" element={<ProtectedRoute><Bale auth={auth} storage={storage} firestore={firestore}/></ProtectedRoute>} />
            <Route path="loading" element={<ProtectedRoute><LoadingScreen /></ProtectedRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </MaterialsContextProvider>
  );
}

export default App;