import React, { useState } from 'react';
import upload_icon from '../assets/icons/upload_photo_icon_light_blue.png';
import logo_circle from '../assets/logos/upcircle_logo_blue512.png';
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../context/firebase';

export const Login = () => {
    const [err,setErr] = useState(false);
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);

    // creating a handle for the form submission
    const handleSubmit = async (e) => {
        // setLoading(true);
        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value;

        try{
            await signInWithEmailAndPassword(auth, email, password);
            // if no errors, navigate to home page:
            navigate("/")
        } catch(err){
            setErr(err);
            // setLoading(false);
            console.log(err.code);
            console.log(err.message);
        }  
    };


  return (
    <>
    <div className="formContainer">
        <div className="formWrapper">
            <img className="logo" src={ logo_circle } alt="" />
            <span className='logo_caption'><span className='brand'>UpCircle</span> Bale Tracking</span>
            <span className='title'>Login</span>
            <form onSubmit={handleSubmit}>
                <input type="email" placeholder="email"/>
                <input type="password" placeholder="password"/>
                <button >Sign in</button>
                {err  && <span className='error'> Wrong username or password.</span> }
            </form>
            {/* Uncomment below to allow users to register.  */}
            {/* <p>Don't have an account yet? <Link to="/register">Register.</Link></p> */}
        </div>
        <div className='upcircleFooter'>
            <span className='brand'>UpCircle</span>
            <span className='year'>2023</span>
        </div>
    </div>
    </>
  )
}

export default Login;
