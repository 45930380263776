import React from "react";
import "./BaleScanner.scss"

import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';

import dashboard_icon from '../../assets/icons/white/dashboard.png';
import checkmark_icon from '../../assets/icons/white/checkmark.png';

export default function BaleScanner(props) {

    const [showCameraFlash, setShowCameraFlash] = React.useState(false);
    const [lastCapturedImage, setLastCapturedImage] = React.useState(null);
    const [captureCounter, setCaptureCounter] = React.useState(0);

    const navigate = useNavigate();

    const webcamRef = React.useRef(null);
    const videoConstraints = {
        aspectRatio: 1,
        width: { min: 480, ideal: 1920, max: 3000 },
        height: { min: 480, ideal: 1920, max: 3000 },
        facingMode: 'environment'
    }

    function base64ToImage(base64str) {
        /** Convert base64 string to image */
        return new Promise(function (resolved, reject) {
            var img = new Image();
            img.onload = function(){
                resolved(img);
            };
            img.src = base64str;
        });
    }

    async function cropBase64Image(base64str) {
        /** Crop image (base64) to square */
        const img = await base64ToImage(base64str);
        if (img.width === img.height) {
            return base64str;
        }

        const length = Math.min(img.width, img.height);
        const offset_x = img.width > img.height ? (img.width - length)/2 : 0;
        const offset_y = img.height > img.width ? (img.height - length)/2 : 0;

        const canvas = document.createElement('canvas');
        canvas.width = length;
        canvas.height = length;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, offset_x, offset_y, length, length, 0, 0, length, length);

        return canvas.toDataURL("image/jpeg", 0.9);
    }

    async function capture() {
        /** Capture camera image and add to new bale */
        const captured_img = webcamRef.current.getScreenshot({
            width: 1920,
            height: 1920,
        });

        if (!captured_img) {
            console.error("Error capturing image");
            return;
        }

        setShowCameraFlash(true);
        setTimeout(() => {
            setShowCameraFlash(false);
        }, 100);

        setLastCapturedImage(captured_img);
        setCaptureCounter(prevcaptureCounter => prevcaptureCounter + 1);
        props.addPhoto(captured_img);
    }

    return (
        <div className="bale-scanner">
            <div className='bale-scanner--camera-container'>
                <Webcam
                    className="camera-preview"
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                />
                <div className="focus-frame">
                    <div className='focus-frame-second'>
                        <p>Bale</p>
                    </div>
                </div>
                { showCameraFlash && <div className="camera-flash"></div> }
            </div>

            <div className='bale-scanner--bottom-menu'>
                <button className="capture-button" onClick={capture}>
                    SCAN
                </button>
                <div className='bale-scanner--bales-overview' onClick={() => navigate(-1)}>
                    <img src={checkmark_icon} alt="link to overview"/>
                </div>
                { lastCapturedImage && <>
                    <div className='bale-scanner--last-bale' onClick={props.viewLastPhoto}>
                        <img src={lastCapturedImage} alt="link to last bale"/>
                    </div>
                    <p className='bale-scanner--counter'>{captureCounter}</p>
                </>}
            </div>
        </div>
    )

}