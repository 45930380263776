import React from "react"
import "./Bales.scss"

import { AuthContext } from '../context/AuthContext';
import {collection, onSnapshot, query, getDoc, doc, setDoc, Timestamp, orderBy, limit} from "firebase/firestore";
import { firestore, auth } from "../context/firebase";
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'

import BaleCard from "../components/BaleCard/BaleCard";
import Navbar from '../components/Navbar/Navbar'

export default function Bales() {
    const MAX_BALES_LOADING = 25;

    const { currentUser } = React.useContext(AuthContext);

    const [unsubscribe, setUnsubscribe] = React.useState(null);
    const [bales, setBales] = React.useState([]);
    const [userCompany, setUserCompany] = React.useState("");

    const navigate = useNavigate();

    async function getUserCompany(uid) {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return "";
        }

        // Sign out if company is not greenworms
        if (userDocSnap.data().company !== "greenworms") {
            signOut(auth);
        }

        return userDocSnap.data().company;
    }

    async function getBales(uid) {
        /** Get all the bales from the user's company */
        const company = await getUserCompany(uid);
        setUserCompany(company);
        const bales_collection = collection(firestore, "clients", company, "bales");
        const bales_query = query(bales_collection, orderBy("date", "desc"), limit(MAX_BALES_LOADING));

        // Unsubscribe from previous query
        if (unsubscribe) {
            unsubscribe();
        }

        const newUnsubscribe = onSnapshot(bales_query, (snapshot) => {
            const newBales = [];
            snapshot.forEach((doc) => {
                newBales.push(doc.data());
            });
            setBales(newBales);
        });
        setUnsubscribe(() => newUnsubscribe);
    }

    React.useEffect(() => {
        if (currentUser && currentUser.uid) {
            getBales(currentUser.uid);
        }

        // Unsubscribe query listener on unmount
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, [ currentUser ])

    async function createNewBale() {
        /** Create a new bale */
        const baleId = String(Math.floor(new Date().getTime()/1000));

        const bales_collection = doc(firestore, "clients", userCompany, "bales", baleId);
        await setDoc(bales_collection, {
            id: baleId,
            image_URL: [],
            material: "25001",
            date: Timestamp.fromDate(new Date()),
            preview_img: "",
            weight: "",
            delivery: "",
            location: "",
            submitted_by: currentUser.uid
        });

        /** Open bale-overview and directly switch to scanning mode */
        navigate(`/bale`, {state: {bale_id: baleId, mode: "bale-overview"}});
        navigate(`/bale`, {state: {bale_id: baleId, mode: "scanning"}});
    }

    return (
        <div className="bales">
            <Navbar/>
            <div className="bales--wrapper">
                <h1 className="bales--title">Bales</h1>
                <div className="bales--cards">
                    {bales.map((bale) => {
                        return <BaleCard key={bale.id} bale={bale} />
                    })}
                </div>
            
                <div className="bales--button-wrapper">
                    <button className="bales--new-button" onClick={createNewBale}>New Bale</button>
                </div>
            </div>
        </div>
    )
}