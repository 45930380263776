import React from "react"
import "./MaterialDropdown.scss"

import { MaterialsContext } from "../../context/MaterialsContext";

export default function MaterialDropdown(props) {
    const { materials } = React.useContext(MaterialsContext);

    /**const productGroupOptions = {};
    for (const [id, material] of Object.entries(materials)) {
        if (Object.keys(productGroupOptions).includes(material.product_group)) continue;
        productGroupOptions[material.product_group] = id;
    }
    productGroupOptions[materials[props.value].product_group] = props.value;

    const materialOptions = {};
    for (const [id, material] of Object.entries(materials)) {
        if (material.product_group == materials[props.value].product_group) {
            materialOptions[material.display_name] = id;
        }
    }**/
    const materialOptions = {};
    for (const [id, material] of Object.entries(materials)) {
        materialOptions[material.display_name] = id;
    }

    return (
        <div className="material-selector">
            {/**<select 
                className="material-dropdown"
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
            >
                {Object.entries(productGroupOptions).map(([value, id]) => {
                    return (
                        <option key={id} value={id}>{value}</option>
                    )
                })}
            </select>**/}

            <select 
                className={ props.highlight ? "material-dropdown material--highlight" : "material-dropdown" }
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
            >
                {Object.entries(materialOptions).map(([value, id]) => {
                    return (
                        <option key={id} value={id}>{value}</option>
                    )
                })}
            </select>
        </div>
    )
}