import React from "react";

import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { firestore } from "../context/firebase";
import { AuthContext } from '../context/AuthContext';

export const MaterialsContext = React.createContext();

export const MaterialsContextProvider = ({children}) => {
    const {currentUser} = React.useContext(AuthContext);
    const [materials, setMaterials] = React.useState({});

    async function getUserCompany(uid) {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return "";
        }
        return userDocSnap.data().company;
    }

    async function getMaterials(){
        if (!currentUser.uid) {
            return;
        }
        const company = await getUserCompany(currentUser.uid);

        const materialsList = {};
        const materialsSnapshot = await getDocs(collection(firestore, "clients", company, "materials"));
        materialsSnapshot.forEach((doc) => {
            materialsList[doc.id] = {...doc.data()};
        });
        setMaterials(materialsList);
    };

    React.useEffect(()=>{
        getMaterials();
    }, [currentUser]);

    return (
        <MaterialsContext.Provider value={{materials}}>
            {children}
        </MaterialsContext.Provider>
    )
};