// Credentials and settings

// Airtable Configs
export const AIRTABLE_API_KEY = "pat2fkStdBHBA1QkP.02cb5b96f2ddf213007f8d525eeb3b18609613b088305000d5efff8563527315";
export const AIRTABLE_BASE_ID = "appZUR1gtQH9YLfxZ";
export const AIRTABLE_ALLBALES_TABLE = "tblsM1cFEm61966UU";
export const AIRTABLE_SCANNEDBALES_TABLE = "tbldCAXxpHpY02ViX";
export const AIRTABLE_USERS_TABLE = "tbl4B1ql2TkJBEW8h";

// Firebase config for bale-app project (now hosted on Analytics Dashboard Project)
export const firebaseConfig = {
    apiKey: "AIzaSyC9K39Yu1OqDsaDdC2q8tPqsTTT9-ksa9M",
    authDomain: "upcircleai-dashboard.firebaseapp.com",
    projectId: "upcircleai-dashboard",
    storageBucket: "upcircleai-dashboard.appspot.com",
    messagingSenderId: "951071303444",
    appId: "1:951071303444:web:0f9f5863f17b2797484401",
    measurementId: "G-QZQ868R21T"
};  
