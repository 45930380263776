import React from 'react'
import './Photo.scss'
import { Modal, Button } from 'react-bootstrap';

import MaterialDropdown from '../components/MaterialDropdown/MaterialDropdown';
import PropertyField from '../components/PropertyField/PropertyField';

import { AuthContext } from '../context/AuthContext';
import { MaterialsContext } from '../context/MaterialsContext';
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../context/firebase";
import { fetchImageUrl } from "../utils/ImgUtils.js"

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';

export default function Photo(props) {
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [baleImg, setBaleImg] = React.useState("");

    React.useEffect(() => {
        fetchImageUrl(props.image_URL).then((url) => {
            setBaleImg( url );
        })
    }, [props.image_URL]);

    return (
        <div className="bale">
            <div className="bale--header">
                <div onClick={props.returnToBale} className="bale--back">
                    <img src={back_icon} alt="Back" className="bale--back-icon"/>
                </div>
                <button onClick={() => setShowDeletePopup(true)} className="bale--delete">Delete</button>
            </div>
            <div className="bale--img-container">
                <img className="bale--img" src={baleImg} />
            </div>

            <Modal 
                show={showDeletePopup}
                onHide={() => setShowDeletePopup(false)}
                backdrop = "static"
                className = "delete-modal">
                <Modal.Header>
                    <Modal.Title>Are you sure you want to delete this photo?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeletePopup(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => {
                            props.deletePhoto(props.image_URL); 
                            setShowDeletePopup(false)
                        }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}