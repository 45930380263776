import React from 'react'
import './Bale.scss'
import { Link, useLocation } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import PropertyField from '../components/PropertyField/PropertyField';
import PhotoCard from '../components/PhotoCard/PhotoCard';
import BaleScanner from '../components/BaleScanner/BaleScanner';
import Photo from './Photo';
import MaterialDropdown from '../components/MaterialDropdown/MaterialDropdown';
import LoadingScreen from './LoadingScreen'
import { fetchImageUrl, fetchImageThumbnailUrl } from '../utils/ImgUtils'

import { AuthContext } from '../context/AuthContext';
import { MaterialsContext } from '../context/MaterialsContext';

import { getDoc, setDoc, deleteDoc, doc, Timestamp, GeoPoint } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { firestore, storage } from "../context/firebase";

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';
import weight_icon from '../assets/icons/white/weight.png';
import date_icon from '../assets/icons/white/calendar.png';
import checkmark_icon from '../assets/icons/white/checkmark.png';

export default function Bale() {
    const location = useLocation();
    const bale_id = location.state.bale_id;
    const mode = location.state.mode;

    const navigate = useNavigate();

    const { currentUser } = React.useContext(AuthContext);
    const { materials } = React.useContext(MaterialsContext);
    const [baleInfo, setBaleInfo] = React.useState({});
    const [userCompany, setUserCompany] = React.useState("");
    const [bales, setBales] = React.useState({});
    const [activePhotoURL, setActivePhotoURL] = React.useState("");
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
    const editTimeout = React.useRef(null);

    async function getUserCompany(uid) {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return "";
        }
        return userDocSnap.data().company;
    }

    async function getBaleInfo(uid) {
        /** Get all bale infos */
        const company = await getUserCompany(uid);
        setUserCompany(company);
        const baleInfoDoc = await getDoc(doc(firestore, "clients", company, "bales", bale_id));
        if (!baleInfoDoc.exists()) {
            return {};
        }

        const baleData = baleInfoDoc.data();
        setBaleInfo( {...baleData, date: baleData.date.toDate().toISOString().split('T')[0]});

        if (mode === 'scanning' && !baleData.location) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(geolocationCallback);
            } else {
                console.log("Geolocation not supported");
            }
        }
    }

    function geolocationCallback(geolocation) {
        setBaleInfo(prevBaleInfo => ({
            ...prevBaleInfo,
            location: new GeoPoint(geolocation.coords.latitude, geolocation.coords.longitude)
        }));
    }

    React.useEffect(() => {
        if (currentUser && currentUser.uid) {
            getBaleInfo(currentUser.uid);
        }
    }, [ currentUser ]);

    async function addPhoto(img) {
        /** Add a new photo to the bale */
        const image_URL = await uploadPhoto(img);
        setBaleInfo(prevBaleInfo => ({
            ...prevBaleInfo,
            image_URL : [...prevBaleInfo.image_URL, image_URL],
            preview_img: prevBaleInfo.preview_img === "" ? image_URL : prevBaleInfo.preview_img
        }));
        setActivePhotoURL(image_URL);
    }

    function base64ToBlob(base64) {
        /** Convert base64 image to blob */
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    };

    async function uploadPhoto(img) {
        return new Promise(async (resolve, reject) => {
            const imageDataBlob = base64ToBlob(img);

            // create unique name for each image (user uid + timestamp, for STORAGE)
            const date = new Date().getTime();
            const storageRef = ref(storage, `bale_images/${currentUser.uid}/${date}.jpg`); 
        
            await uploadBytesResumable(storageRef, imageDataBlob)
            const downloadURL = await getDownloadURL(storageRef)
            resolve(downloadURL);
        });
    }

    async function deletePhoto(img) {
        const image_URL = await fetchImageUrl(img);
        const image_thumb_URL = await fetchImageThumbnailUrl(img);

        /** Navigate back to bales page */
        navigate(-1);

        setBaleInfo(prevBaleInfo => ({
            ...prevBaleInfo,
            image_URL: prevBaleInfo.image_URL.filter(prev_img => prev_img !== img),
        }));

        /** Delete bale image and thumbnail from storage */
        await deleteObject(ref(storage, image_URL));
        await deleteObject(ref(storage, image_thumb_URL));
    }

    async function deleteBale() {
        /** Delete all photos from bale */
        clearTimeout(editTimeout.current);
        setShowLoadingScreen(true);

        for (const img of baleInfo.image_URL) {
            const image_URL = await fetchImageUrl(img);
            const image_thumb_URL = await fetchImageThumbnailUrl(img);
            await deleteObject(ref(storage, image_URL));
            await deleteObject(ref(storage, image_thumb_URL));
        }

        /** Delete bale from database */
        await deleteDoc(doc(firestore, "clients", userCompany, "bales", bale_id));

        /** Navigate back to deliveries page */
        navigate(-1);
    }

    function handleBaleFieldChange(event) {
        const { name, value } = event.target;
        setBaleInfo(prevBaleInfo => ({...prevBaleInfo, [name]: value}));
    }

    async function closePhotoView() {
        navigate(-1);
    }

    async function returnToBalesOverview() {
        // If there are no bales in the delivery, delete the delivery
        if (baleInfo.image_URL.length === 0) {
            await deleteBale();
            return;
        }

        // Save changes to database
        const baleRef = doc(firestore, "clients", userCompany, "bales", bale_id);
        await setDoc(baleRef, {...baleInfo, date: Timestamp.fromDate(new Date(baleInfo.date))});

        // Navigate back to bales page
        navigate(-1);
    }

    React.useEffect(() => {
        // Save changes to database after 1 second of inactivity
        clearTimeout(editTimeout.current);

        if (baleInfo) {
            editTimeout.current = setTimeout(async () => {
                const baleRef = doc(firestore, "clients", userCompany, "bales", bale_id);
                await setDoc(baleRef, {...baleInfo, date: Timestamp.fromDate(new Date(baleInfo.date))});
            }, 1000);
        }
    }, [baleInfo]);

    if (showLoadingScreen || !(baleInfo && baleInfo.material && materials) ) {
        return <LoadingScreen />
    } else if (mode === 'scanning') {
        return (
            <div className="bale">
                <div className="bale--header">
                    <div onClick={() => navigate(-1)} className="bale--back">
                        <img src={back_icon} alt="Back" className="bale--back-icon"/>
                    </div>
                    <h3 className="bale--title">Bale ID #{bale_id}</h3>
                </div>

                <BaleScanner
                    addPhoto={addPhoto}
                    viewLastPhoto={() => navigate(`/bale`, {state: {bale_id: bale_id, mode: "photo-overview"}})}
                />
            </div>
        )
    } else if (mode === 'photo-overview') {
        return (
            <Photo 
                image_URL={activePhotoURL}
                deletePhoto={deletePhoto}
                returnToBale={closePhotoView}
            />
        )
    } else {
        return (
            <div className="bale">
                <div className="bale--header">
                    <div onClick={returnToBalesOverview} className="bale--back">
                        <img src={back_icon} alt="Back" className="bale--back-icon"/>
                    </div>
                    <h3 className="bale--title">Bale #{bale_id}</h3>
                    <button onClick={() => setShowDeletePopup(true)} className="bale--delete">Delete</button>
                </div>

                <div className="bale--content">
                    <MaterialDropdown
                        name = "material"
                        value = {baleInfo.material}
                        handleChange={handleBaleFieldChange}
                    />
                    
                    <div className="bale--properties">
                        <PropertyField 
                            icon={weight_icon} 
                            title="WEIGHT KG" 
                            value={baleInfo.total_weight_kg} 
                            name="total_weight_kg"
                            type="number"
                            handleChange={handleBaleFieldChange}
                            editable={true}
                        />
                        <PropertyField 
                            icon={date_icon} 
                            title="DATE" 
                            value={baleInfo.date} 
                            name="date"
                            type="date"
                            handleChange={handleBaleFieldChange}
                            editable={true}
                        />
                    </div>

                    <h3 className="bale--bales-title">Registered Photos</h3>
                    <div className="bale--bales">
                        <div className="bale--bales-card-wrapper">
                            {baleInfo.image_URL.map(image_URL => {
                                return (
                                    <div onClick={() => { setActivePhotoURL(image_URL); navigate(`/bale`, {state: {bale_id: bale_id, mode: "photo-overview"}})}} key={image_URL}>
                                        <PhotoCard key={image_URL} 
                                            image_URL={image_URL}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="bale--button-wrapper">
                    <button className="bale--add-button" onClick={() => navigate(`/bale`, {state: {bale_id: bale_id, mode: "scanning"}})}>Add Photo</button>
                    <button className="bale--accept-button" onClick={returnToBalesOverview}>
                        <img src={checkmark_icon}/>
                    </button>
                </div>

                <Modal 
                    show={showDeletePopup}
                    onHide={() => setShowDeletePopup(false)}
                    backdrop = "static"
                    className = "delete-modal">
                    <Modal.Header>
                        <Modal.Title>Are you sure you want to delete this bale?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeletePopup(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteBale}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}