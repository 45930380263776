import React from 'react'
import './BaleCard.scss'
import { Link } from 'react-router-dom';
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'

import { MaterialsContext } from '../../context/MaterialsContext';

export default function BaleCard(props) {
    const [baleImg, setBaleImg] = React.useState("");

    const stateObj = {bale_id: props.bale.id, mode: "bale_overview"}
    const { materials } = React.useContext(MaterialsContext);

    const material = materials[props.bale.material]

    React.useEffect(() => {
        fetchImageThumbnailUrl(props.bale.preview_img).then((url) => {
            setBaleImg(url)
        })
    }, [props.bale.preview_img])

    return (
        <Link to="/bale" state={stateObj} className="bale_card">
            <div className="bale--img-container">
                { baleImg && <img className="bale--preview-img" src={baleImg}/> }
                { props.bale.weight && <p className="bale_card--weight">{`${props.bale.weight} Kg`}</p> }
                { material && <div className="bale_card--material">
                    <p>{material.product_group}</p>
                    <p>{material.display_name}</p>
                </div> }
            </div>
            <div className="bale--title-div">
                <p className="bale--title">{"Bale #" + props.bale.id}</p>
                <p className="bale--date">{props.bale.date.toDate().toLocaleDateString()}</p>
            </div>
        </Link>
    )
}