import React from 'react'
import "./PhotoCard.scss"
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'

export default function PhotoCard(props) {
    const [baleImg, setBaleImg] = React.useState("");

    React.useEffect(() => {
        if (props.image_URL.startsWith("data:image")) {
            // This means it is not actually an URL but the image data itself
            setBaleImg(props.image_URL);
        } else {
            fetchImageThumbnailUrl(props.image_URL).then((url) => {
                setBaleImg(url);
            })
        }
    }, [props.image_URL]);

    return (
        <div className="photo_card">
            { baleImg && <img className="photo_card--img" src={baleImg}/> }
        </div>
    )
}